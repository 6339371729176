@import './colours';

@font-face {
  font-family: 'Faktum';
  src: url(../assets/fonts/Faktum-Regular.otf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Faktum';
  src: url(../assets/fonts/Faktum-Regular-Italic.otf) format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Faktum';
  src: url(../assets/fonts/Faktum-Bold.otf) format('truetype');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Faktum';
  src: url(../assets/fonts/Faktum-Bold-Italic.otf) format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Faktum';
  src: url(../assets/fonts/Faktum-Medium.otf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Faktum';
  src: url(../assets/fonts/Faktum-Medium-Italic.otf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Faktum';
  src: url(../assets/fonts/Faktum-SemiBold.otf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Faktum';
  src: url(../assets/fonts/Faktum-SemiBold-Italic.otf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

//Fonts
$font-family: 'Faktum', sans-serif;

//Typography

%H1 {
  font-size: 48px;
  line-height: 1.25;
  font-weight: bold;
}
%H2 {
  font-size: 40px;
  line-height: 1.22;
  font-weight: bold;
}
%H3 {
  font-size: 20px;
  line-height: 1.33;
  font-weight: bold;
}
%S1 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600; //semi-bold as per https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
}
%S2 {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
}
%body-large {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
}
%body-small {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}
%counters {
  font-size: 12px;
  line-height: 1.23;
  font-weight: 500;
}
%buttons {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}
.H1 {
  @extend %H1;
}
.H2 {
  @extend %H2;
}
.H3 {
  @extend %H3;
}
.S1 {
  @extend %S1;
}
.S2 {
  @extend %S2;
}
.body-large {
  @extend %body-large;
}
.body-small {
  @extend %body-small;
}
.counters {
  @extend %counters;
}
.buttons {
  @extend %buttons;
}

.link {
  font-weight: 500;
  color: $blue-medium;
  cursor: pointer;

  &.text {
    text-decoration: none;
  }

  &.external {
    text-decoration: underline;
  }

  &:hover {
    background-color: $mono-grey-1;
  }
}
