//Rogue colours found , used outside of colours.scss
$blue-gray: #8fa1b2;
$light-raspberry: #eb5368;
$light-black:#14171a;
$dark-grey:#252c33;
$light-blue: #a9bacb;
$torqoise: #5e88b0;
$dark-blue: #181d20;
$light-grey: #f2f6fc;


//Typography colours
$navy: #191530;
$mono-grey-6: #526482;
$mono-grey-5: #7989A6;
$mono-grey-4: #ABB8CF;
$pp-wild-blue-yonder: #aeb8cc;

//UI Backgrounds
$mono-grey-4: #C4C4C4;
$mono-grey-3: #CED9EB;
$mono-grey-2: #DFE7F5;
$mono-grey-1: #F7FAFF;
$white: #ffffff;

//Primary Palette (500 is base colour, other numbers are offsets)
$sunset-orange-500: #fb6b53;
$blue-medium: #007CB6;
$green-medium: #008851;
$sunglow-500: #f5c131;
$orange-medium: #DA3441;
$pink-medium: #DE1B76;

$pink-bg: #FFCAEA;
$pink-light: #F690C4;
$pink-dark:#A40F58;

$blue-bg:  #B9E1F9;
$blue-light:  #7BCCF1;
$denim-100: #acc9fa;
$denim-200: #7cabfc;
$denim-300: #488afa;
$denim-400: #2e76f2;
$blue-dark: #003C71;
$denim-800: #0e3578;
$denim-900: #0d2040;

$green-bg: #D3EFD4;
$leaf-green-100: #b0f7e5;
$green-light: #76DEAB;
$leaf-green-300: #0bb88c;
$leaf-green-400: #099e79;
$leaf-green-600: #0e785d;
$leaf-green-700: #0f7058;
$green-dark: #1B4F3C;
$leaf-green-900: #0c382d;

$sunglow-50: #fcf9f0;
$sunglow-100: #fae7ac;
$sunglow-200: #fadc82;
$sunglow-300: #fcd056;
$sunglow-400: #fac83e;
$yellow-medium: #F6C26D;
$sunglow-700: #e3ac14;
$yellow-dark: #976D19;

$orange-bg:  #F9D2C4;
$french-raspberry-100: #faacb9;
$orange-light: #F99C90;
$french-raspberry-300: #f73e5d;
$french-raspberry-400: #f02446;
$orange-dark: #892937;
$orange-dark: #892937;
$french-raspberry-800: #940019;
$french-raspberry-900: #360c13;

// Social
$pp-google: #DB4437;
