@import './typography';
@import './colours';

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

*:focus {
  outline: none;
}

body {
  overflow: hidden;
  height: 100vh;

  margin: 0;
  padding: 0;

  font-family: $font-family;
  color: $navy;
}

textarea,
input,
button {
  font-family: $font-family;
  outline: none;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.bg-1-overlay {
    background-color: $navy;
    opacity: 0.5;
  }
}
.cdk-overlay-pane {
  &.confirm-chanes-modal {
    overflow: auto;
  }

  &.inherit-overflow > *:first-child {
    overflow: inherit;
  }
  &.dash2-tooltip-pane {
    position: absolute;
    margin-left: calc(100% - 415px);
  }
}
